import Gem from "components/gem";
import React, { useMemo } from "react";

interface PointProps {
  name: string;
}

export default function Point({ name }: PointProps) {
  return useMemo(() => {
    switch (name) {
      case "knowledge":
        return <Gem type="knowledge" size={16} />;
      case "skill":
        return <Gem type="skill" size={16} />;
      case "motivation":
        return <Gem type="motivation" size={16} />;
      case "lifeskill_sexuality":
        return <Gem type="sexuality" size={16} />;
      case "lifeskill_emotion":
        return <Gem type="emotion" size={16} />;
      case "lifeskill_relationship":
        return <Gem type="relationship" size={16} />;
      default:
        return <Gem type="knowledge" size={16} />;
    }
  }, [name]);
}
