import { useState, useEffect, PropsWithChildren } from "react";
import { gems } from "utils/gems";

type GemProps = {
  type:
    | "knowledge"
    | "skill"
    | "motivation"
    | "sexuality"
    | "emotion"
    | "relationship";
  size?: number;
};

export default function Gem({ type, size }: GemProps) {
  const [data, setData] = useState<any>();

  useEffect(() => {
    if (!type) return;
    const gemsData = gems.find((item: any) => item.id === type);
    setData(gemsData);
  }, [type]);

  if (!data) return <></>;

  return (
    <div
      className="w-full relative aspect-square flex items-center justify-center"
      style={size ? { width: size, height: size } : {}}
    >
      <img
        src={data.image}
        alt={data.name}
        className="w-full h-full object-contain absolute"
      />
      {/* <label>
        <span>{data.name}</span>
      </label> */}
    </div>
  );
}
